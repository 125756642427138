import React, { useEffect } from "react";
import { Form, Switch, Typography } from "antd";

import WarantyTag from "./WarantyTag";
import InputNumberSlider from "../InputNumberSlider";
import GlobalContext from "../../contexts/GlobalContext";

const OptionalWaranty = ({
  borderColor,
  title,
  description,
  available,
  inputVisible,
  switchVisible,
  options,
  switchName,
  inputOptions,
  switchDefaultValue,
}) => {
  const { globalData, setGlobalData } = React.useContext(GlobalContext);
  const [switchState, setSwitchState] = React.useState(
    switchDefaultValue ?? false
  );

  const handleChangeSwitch = (e) => {
    setSwitchState(e);
    setGlobalData({
      ...globalData,
      garanties: { ...globalData.garanties, [switchName]: e },
    });
  };

  return (
    <div
      style={{
        backgroundColor: available ? "white" : "#FFE8E6",
        border: borderColor ? `3px solid ${borderColor}` :"3px solid #E00000",
        borderRadius: 13,
        margin: "12px 0",
        padding: "0 10px",
        height: '100%',
        display: 'flex',
        flexDirection: 'column',

      }}
    >
      <Typography.Title level={4} style={{ textAlign: "center" }}>
        {title}
      </Typography.Title>
      <Typography.Paragraph style={{ textAlign: "center" }}>
        {description}
      </Typography.Paragraph>
      {switchVisible && (
        <>
          {available ? (
            <Form.Item
              style={{ display: "flex", justifyContent: "center" }}
              name={switchName}
            >
              <Switch
                defaultChecked={switchDefaultValue}
                checkedChildren={"Oui"}
                unCheckedChildren={"Non"}
                onChange={(e) => handleChangeSwitch(e)}
              />
            </Form.Item>
          ) : (
            <div
              style={{
                margin: "10px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <WarantyTag available={available} />
            </div>
          )}{" "}
        </>
      )}

      {switchState ? options : null}

      {available && inputVisible && (
        <div style={{ width: "80%", margin: "0 auto" }}>
          <InputNumberSlider {...inputOptions} disabled={!switchState} />
        </div>
      )}
    </div>
  );
};

export default OptionalWaranty;
